// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainCard_container__M-wTD{
    background-color: var(--button-color);
    width: 320px;
    height: 150px;

    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    color:var(--text-color-white);
    font-size: 24px;
    box-shadow: 3px 3px 10px rgba(0,0,0, 0.6);
}

@media (width<400){
    .MainCard_container__M-wTD{
        width: 260px;
        height: 120px;
    }
}

`, "",{"version":3,"sources":["webpack://./src/components/Card/MainCard.module.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,YAAY;IACZ,aAAa;;IAEb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,6BAA6B;IAC7B,eAAe;IACf,yCAAyC;AAC7C;;AAEA;IACI;QACI,YAAY;QACZ,aAAa;IACjB;AACJ","sourcesContent":[".container{\r\n    background-color: var(--button-color);\r\n    width: 320px;\r\n    height: 150px;\r\n\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    border-radius: 15px;\r\n    color:var(--text-color-white);\r\n    font-size: 24px;\r\n    box-shadow: 3px 3px 10px rgba(0,0,0, 0.6);\r\n}\r\n\r\n@media (width<400){\r\n    .container{\r\n        width: 260px;\r\n        height: 120px;\r\n    }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MainCard_container__M-wTD`
};
export default ___CSS_LOADER_EXPORT___;
