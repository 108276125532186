// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TestScreen_container__TD4uV{
    width:360px;
    border: 1px solid var(--button-color);
    border-radius: 12px;
    padding:10px;
    margin: 10px;    
}

.TestScreen_level__cMan2{
    width:100%;
    display: flex;
    justify-content: flex-end;
}

.TestScreen_instruction__zAjUQ{
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;    
    padding-block: 25px;    
    font-size: 20px;
}

.TestScreen_content__ZS8xo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 200px;
}

.TestScreen_buttonList__ySVFd{
    display: flex;
    flex-direction: column;
    gap:5px;
    width: 100%;    
    margin-top: 20px;
}

.TestScreen_buttonList__ySVFd button{
    width: 100%;
}

.TestScreen_responseButton__JI9XH{
    display: flex;
    justify-content: center;   
    gap: 30px;      
}

.TestScreen_commonButtons__2FWHp{
    display: flex;
    justify-content: center;   
    gap: 30px;      
}

.TestScreen_correct__sUCMO {
    background-color: #16680E;
    
}

.TestScreen_wrong__gfQPm {
    background-color: #730D0D;    
}


`, "",{"version":3,"sources":["webpack://./src/components/Matrixpage/TestScreen.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,qCAAqC;IACrC,mBAAmB;IACnB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,kBAAkB;IAClB,WAAW;IACX,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,OAAO;IACP,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,SAAS;AACb;;AAEA;IACI,yBAAyB;;AAE7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".container{\r\n    width:360px;\r\n    border: 1px solid var(--button-color);\r\n    border-radius: 12px;\r\n    padding:10px;\r\n    margin: 10px;    \r\n}\r\n\r\n.level{\r\n    width:100%;\r\n    display: flex;\r\n    justify-content: flex-end;\r\n}\r\n\r\n.instruction{\r\n    display: flex;\r\n    justify-content: center;\r\n    text-align: center;\r\n    width: 100%;    \r\n    padding-block: 25px;    \r\n    font-size: 20px;\r\n}\r\n\r\n.content{\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    width: 100%;\r\n    min-height: 200px;\r\n}\r\n\r\n.buttonList{\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap:5px;\r\n    width: 100%;    \r\n    margin-top: 20px;\r\n}\r\n\r\n.buttonList button{\r\n    width: 100%;\r\n}\r\n\r\n.responseButton{\r\n    display: flex;\r\n    justify-content: center;   \r\n    gap: 30px;      \r\n}\r\n\r\n.commonButtons{\r\n    display: flex;\r\n    justify-content: center;   \r\n    gap: 30px;      \r\n}\r\n\r\n.correct {\r\n    background-color: #16680E;\r\n    \r\n}\r\n\r\n.wrong {\r\n    background-color: #730D0D;    \r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TestScreen_container__TD4uV`,
	"level": `TestScreen_level__cMan2`,
	"instruction": `TestScreen_instruction__zAjUQ`,
	"content": `TestScreen_content__ZS8xo`,
	"buttonList": `TestScreen_buttonList__ySVFd`,
	"responseButton": `TestScreen_responseButton__JI9XH`,
	"commonButtons": `TestScreen_commonButtons__2FWHp`,
	"correct": `TestScreen_correct__sUCMO`,
	"wrong": `TestScreen_wrong__gfQPm`
};
export default ___CSS_LOADER_EXPORT___;
