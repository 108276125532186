// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Instruction_container__H8yqF {
    width: 300px;
    display:flex;
    flex-direction: column;
    padding: 15px;
    border: 1px solid var(--button-color);
    border-radius: 15px;
    gap:20px;
}

.Instruction_title__g2\\+ub{
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
}

.Instruction_body__1A8AL{
    font-size: 16px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap:20px;
    align-items: center;
}

.Instruction_instruction__1skEz{
    font-size: 20px;
}

.Instruction_example__Udpqe{
    color: var(--text-color);
    border: 1px solid var(--text-color);
    border-radius: 10px;
    width:80%;
    text-align:left;
    padding:10px;
    font-size: 18px;
}

.Instruction_buttonContainer__cuKu-{
    width: 100%;
    display: flex;
    justify-content: center;
    gap:10px;
}

.Instruction_button__qLtDc{
    background-color: var(--button-color);
    color:var(--text-color-white);
    border:none;
    outline:none;
    padding: 10px 30px;
    border-radius: 15px;
    cursor:pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/Instruction/Instruction.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,aAAa;IACb,qCAAqC;IACrC,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,wBAAwB;IACxB,mCAAmC;IACnC,mBAAmB;IACnB,SAAS;IACT,eAAe;IACf,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,QAAQ;AACZ;;AAEA;IACI,qCAAqC;IACrC,6BAA6B;IAC7B,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;AAClB","sourcesContent":[".container {\r\n    width: 300px;\r\n    display:flex;\r\n    flex-direction: column;\r\n    padding: 15px;\r\n    border: 1px solid var(--button-color);\r\n    border-radius: 15px;\r\n    gap:20px;\r\n}\r\n\r\n.title{\r\n    width: 100%;\r\n    text-align: center;\r\n    font-size: 24px;\r\n    font-weight: bold;\r\n}\r\n\r\n.body{\r\n    font-size: 16px;\r\n    text-align: center;\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap:20px;\r\n    align-items: center;\r\n}\r\n\r\n.instruction{\r\n    font-size: 20px;\r\n}\r\n\r\n.example{\r\n    color: var(--text-color);\r\n    border: 1px solid var(--text-color);\r\n    border-radius: 10px;\r\n    width:80%;\r\n    text-align:left;\r\n    padding:10px;\r\n    font-size: 18px;\r\n}\r\n\r\n.buttonContainer{\r\n    width: 100%;\r\n    display: flex;\r\n    justify-content: center;\r\n    gap:10px;\r\n}\r\n\r\n.button{\r\n    background-color: var(--button-color);\r\n    color:var(--text-color-white);\r\n    border:none;\r\n    outline:none;\r\n    padding: 10px 30px;\r\n    border-radius: 15px;\r\n    cursor:pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Instruction_container__H8yqF`,
	"title": `Instruction_title__g2+ub`,
	"body": `Instruction_body__1A8AL`,
	"instruction": `Instruction_instruction__1skEz`,
	"example": `Instruction_example__Udpqe`,
	"buttonContainer": `Instruction_buttonContainer__cuKu-`,
	"button": `Instruction_button__qLtDc`
};
export default ___CSS_LOADER_EXPORT___;
