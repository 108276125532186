export function getFilenamesInWords(startNumber, endNumber, isRandomise) {
    const numberWords = [
      'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten',
      'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'
    ];
  
    const tensWords = ['twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
  
    const filenames = [];
  
    for (let number = startNumber; number <= endNumber; number++) {
      if (number >= 1 && number <= 19) {
        filenames.push(`${numberWords[number - 1]}.wav`);
      } else if (number >= 20 && number <= 99) {
        const tensDigit = Math.floor(number / 10);
        const onesDigit = number % 10;
  
        if (onesDigit === 0) {
          filenames.push(`${tensWords[tensDigit - 2]}.wav`);
        } else {
          filenames.push(`${tensWords[tensDigit - 2]}${numberWords[onesDigit - 1]}.wav`);
        }
      } else {
        filenames.push('Invalid number');
      }
    }
  
    console.log("Randomise setting:", isRandomise)
    
    if(isRandomise==="true")
    { 
      return shuffleArray(filenames);
    }else
    {
      return filenames;
    }
}

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
    
export function setFolderNames(pos, folderName, folderArray, updateFolderNames){      

      const data = [...folderArray];
      const newArray = [];

      console.log("adding folder to array", pos, folderArray.length, folderName);
 
      if(data.length>0){      
        for(let i=0;i<pos;i++)          
        newArray.push(data[i])        
      }
      newArray.push(folderName.toLowerCase());     
      // console.log(pos, folderName, folderArray, newArray);
      updateFolderNames(newArray);
}

export function getFolderNames(folderArray){  
  const folderPath = folderArray.join('/');    
  return folderPath;
}

const numberWordsToNumber = {
  one: 1,
  two: 2,
  three: 3,
  four: 4,
  five: 5,
  six: 6,
  seven: 7,
  eight: 8,
  nine: 9,
  ten: 10,
  eleven: 11,
  twelve: 12,
  thirteen: 13,
  fourteen: 14,
  fifteen: 15,
  sixteen: 16,
  seventeen: 17,
  eighteen: 18,
  nineteen: 19,
  twenty: 20,
  thirty: 30,
  forty: 40,
  fifty: 50,
  sixty: 60,
  seventy: 70,
  eighty: 80,
  ninety: 90,
};

const convertWordToNumber = (word) => {
  const parts = word.match(/([a-z]+)/g);
  let number = 0;

  if (parts) {
    parts.forEach(part => {
      number += numberWordsToNumber[part] || 0;
    });
  }

  return number;
};

export const convertAndReduceNumber = (audioFileName) => {
  const baseName = audioFileName.replace('.wav', ''); // Remove .wav extension
  const num = convertWordToNumber(baseName);
  return num - 1; // Reduce by 1
};

