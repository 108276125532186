import styles from './SubCard.module.css';
export default function SubCard({heading, subheading, handleClick, instruction, example, folderName, isRandomise}){        
    return(     
            <div className = {styles.container} id = {heading} data-instruction = {instruction} data-example = {example} data-foldername = {folderName} data-israndomise = {isRandomise} onClick={handleClick}>
                <div className = {styles.heading} id = {heading} data-instruction = {instruction} data-example = {example} data-foldername = {folderName} data-israndomise = {isRandomise}>{heading}</div>
                {
                    subheading!==""?(<div className = {styles.subheading} data-instruction = {instruction} data-example = {example} data-foldername = {folderName} id = {heading} data-israndomise = {isRandomise} >{subheading}</div>):null
                }
            </div>       
    )
}