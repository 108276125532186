// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginCard_container__e\\+ftG{
    background-color: var(--button-color);
    
    width:300px;
    min-height: 350px;
    border-radius: 30px;
    padding:10px;

    display: flex;
    flex-direction: column;    
}

.LoginCard_heading__7AcCo{
    font-family: sans-serif;
    font-size: 25px;
    color:var(--text-color-white);
    margin-top: 20px;

    display: flex;
    justify-content: center;
}

.LoginCard_loginForm__JXatw{
    margin-top: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/LandingPage/LoginCard.module.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;;IAErC,WAAW;IACX,iBAAiB;IACjB,mBAAmB;IACnB,YAAY;;IAEZ,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,uBAAuB;IACvB,eAAe;IACf,6BAA6B;IAC7B,gBAAgB;;IAEhB,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".container{\r\n    background-color: var(--button-color);\r\n    \r\n    width:300px;\r\n    min-height: 350px;\r\n    border-radius: 30px;\r\n    padding:10px;\r\n\r\n    display: flex;\r\n    flex-direction: column;    \r\n}\r\n\r\n.heading{\r\n    font-family: sans-serif;\r\n    font-size: 25px;\r\n    color:var(--text-color-white);\r\n    margin-top: 20px;\r\n\r\n    display: flex;\r\n    justify-content: center;\r\n}\r\n\r\n.loginForm{\r\n    margin-top: 30px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `LoginCard_container__e+ftG`,
	"heading": `LoginCard_heading__7AcCo`,
	"loginForm": `LoginCard_loginForm__JXatw`
};
export default ___CSS_LOADER_EXPORT___;
