// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_container__dbQGO{
    padding: 5px;
}

button{  
    padding: 10px 24px;
    border-radius: 24px;    
    color:#FFFFFF;
    font-size: 18px;
    border: none;
    outline:none;
    letter-spacing: 1px;
    min-width: 120px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Testpage/Button.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;IACb,eAAe;IACf,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".container{\r\n    padding: 5px;\r\n}\r\n\r\nbutton{  \r\n    padding: 10px 24px;\r\n    border-radius: 24px;    \r\n    color:#FFFFFF;\r\n    font-size: 18px;\r\n    border: none;\r\n    outline:none;\r\n    letter-spacing: 1px;\r\n    min-width: 120px;\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Button_container__dbQGO`
};
export default ___CSS_LOADER_EXPORT___;
