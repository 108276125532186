// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  --button-color: #ACA8EE;
  --text-color: #4D3EDE;
  --text-color-white : #FFFFFF;
  --commonButton-color: #35155D

  background-color: #EEEEEE;
}

body {
  margin: 0;
  padding:0;  
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,mEAAmE;EACnE,gBAAgB;EAChB,gBAAgB;;EAEhB,uBAAuB;EACvB,qBAAqB;EACrB,4BAA4B;EAC5B;;2BAEyB;AAC3B;;AAEA;EACE,SAAS;EACT,SAAS;AACX","sourcesContent":[":root {\n  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;\n  line-height: 1.5;\n  font-weight: 400;\n\n  --button-color: #ACA8EE;\n  --text-color: #4D3EDE;\n  --text-color-white : #FFFFFF;\n  --commonButton-color: #35155D\n\n  background-color: #EEEEEE;\n}\n\nbody {\n  margin: 0;\n  padding:0;  \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
