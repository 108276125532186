// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Popup_overlay__Rlo3p {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Popup_popup__DDMD3 {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  button {
    color:#FFFFFF;
    background-color: var(--button-color);
  }`, "",{"version":3,"sources":["webpack://./src/components/Popup/Popup.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,0CAA0C;IAC1C,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,qCAAqC;EACvC","sourcesContent":[".overlay {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    background-color: rgba(0, 0, 0, 0.5);\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n  }\r\n  \r\n  .popup {\r\n    background-color: white;\r\n    padding: 20px;\r\n    border-radius: 8px;\r\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);\r\n    text-align: center;\r\n  }\r\n\r\n  button {\r\n    color:#FFFFFF;\r\n    background-color: var(--button-color);\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `Popup_overlay__Rlo3p`,
	"popup": `Popup_popup__DDMD3`
};
export default ___CSS_LOADER_EXPORT___;
