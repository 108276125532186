// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SubCard_container__uivyZ{
    background-color: var(--button-color);
    width: 200px;
    height: 100px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    color:var(--text-color-white);    
    text-align: center;    
    box-shadow: 3px 3px 10px rgba(0,0,0, 0.6);
    
}

.SubCard_heading__hulBl{
    font-size: 18px;
}

.SubCard_subheading__UiRV8{
    font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/Card/SubCard.module.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,YAAY;IACZ,aAAa;;IAEb,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,6BAA6B;IAC7B,kBAAkB;IAClB,yCAAyC;;AAE7C;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".container{\r\n    background-color: var(--button-color);\r\n    width: 200px;\r\n    height: 100px;\r\n\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    border-radius: 15px;\r\n    color:var(--text-color-white);    \r\n    text-align: center;    \r\n    box-shadow: 3px 3px 10px rgba(0,0,0, 0.6);\r\n    \r\n}\r\n\r\n.heading{\r\n    font-size: 18px;\r\n}\r\n\r\n.subheading{\r\n    font-size: 14px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SubCard_container__uivyZ`,
	"heading": `SubCard_heading__hulBl`,
	"subheading": `SubCard_subheading__UiRV8`
};
export default ___CSS_LOADER_EXPORT___;
