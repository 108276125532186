// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_layout__FNre2{
    width: 100vw;   
    width: 100%;    
    display: flex;      
    flex-direction: column;
    align-items: center;    
}

.Layout_navbar__-T8Lm{
   height: 56px;   
   width:100%; 
}

.Layout_body__08Au3{   
   max-width:80vw;   
   width: 100%;
   height: calc(100vh - 56px);   

   display: flex;
   justify-content: center;
   align-items: center;

   color:black;
   background-color: #FFFFFF; 
}



@media (width<600px){
 .Layout_body__08Au3{
    max-width:100vw;
    width:100%;
 }   
} `, "",{"version":3,"sources":["webpack://./src/components/Layout/HomePage/Layout.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;GACG,YAAY;GACZ,UAAU;AACb;;AAEA;GACG,cAAc;GACd,WAAW;GACX,0BAA0B;;GAE1B,aAAa;GACb,uBAAuB;GACvB,mBAAmB;;GAEnB,WAAW;GACX,yBAAyB;AAC5B;;;;AAIA;CACC;IACG,eAAe;IACf,UAAU;CACb;AACD","sourcesContent":[".layout{\r\n    width: 100vw;   \r\n    width: 100%;    \r\n    display: flex;      \r\n    flex-direction: column;\r\n    align-items: center;    \r\n}\r\n\r\n.navbar{\r\n   height: 56px;   \r\n   width:100%; \r\n}\r\n\r\n.body{   \r\n   max-width:80vw;   \r\n   width: 100%;\r\n   height: calc(100vh - 56px);   \r\n\r\n   display: flex;\r\n   justify-content: center;\r\n   align-items: center;\r\n\r\n   color:black;\r\n   background-color: #FFFFFF; \r\n}\r\n\r\n\r\n\r\n@media (width<600px){\r\n .body{\r\n    max-width:100vw;\r\n    width:100%;\r\n }   \r\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `Layout_layout__FNre2`,
	"navbar": `Layout_navbar__-T8Lm`,
	"body": `Layout_body__08Au3`
};
export default ___CSS_LOADER_EXPORT___;
