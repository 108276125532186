
import appLogo from '../../../assets/Earlogo.png'
export default function Heading() {
    return(
        <>
            <img src={appLogo} alt ='App Logo' />
            <h1>
                CAPD
            </h1>
        </>
    )
}
