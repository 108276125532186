// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LabelTextInput_container__sazLi{
    width:250px;   
    padding: 5px;
    display: flex;
    flex-direction: column;    
    /* margin-left: 20px; */    
}   

.LabelTextInput_inputLabel__EFCff{
    width:80px;    
    padding-left: 0px;
    margin-left: 0px;
   
}

.LabelTextInput_inputText__PgvF-{
    padding: 10px 5px;
    border-radius: 5px;
    border: none;
    outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Form/LabelTextInput.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,gBAAgB;;AAEpB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;IACZ,aAAa;AACjB","sourcesContent":[".container{\r\n    width:250px;   \r\n    padding: 5px;\r\n    display: flex;\r\n    flex-direction: column;    \r\n    /* margin-left: 20px; */    \r\n}   \r\n\r\n.inputLabel{\r\n    width:80px;    \r\n    padding-left: 0px;\r\n    margin-left: 0px;\r\n   \r\n}\r\n\r\n.inputText{\r\n    padding: 10px 5px;\r\n    border-radius: 5px;\r\n    border: none;\r\n    outline: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `LabelTextInput_container__sazLi`,
	"inputLabel": `LabelTextInput_inputLabel__EFCff`,
	"inputText": `LabelTextInput_inputText__PgvF-`
};
export default ___CSS_LOADER_EXPORT___;
