// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navbar_container__Jqvfn{
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height: 56px;
    background-color: var(--button-color);
    display: flex;        
    align-items: center;
}

.Navbar_nav__mtp4d{
    width: 100%;
    padding-inline:40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Navbar_logo__nkjp8{
    height: 30px;
    width:30px;
}

.Navbar_logo__nkjp8 img{
    width:100%;
    height: 100%;
}

.Navbar_buttonContainer__Ub2ms{
    display: flex;
    gap:10px;
}

.Navbar_button__hTaf2{
    color: var(--text-color);
    border:none;
    outline:none;
    padding:8px 20px;
    border-radius: 8px;
    box-shadow: 1px 1px 1p rgb(0,0,0.5);
    background-color: var(--text-color-white);
    cursor: pointer;
}

`, "",{"version":3,"sources":["webpack://./src/components/Layout/HomePage/Navbar.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,MAAM;IACN,KAAK;IACL,UAAU;IACV,YAAY;IACZ,qCAAqC;IACrC,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,QAAQ;AACZ;;AAEA;IACI,wBAAwB;IACxB,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,mCAAmC;IACnC,yCAAyC;IACzC,eAAe;AACnB","sourcesContent":[".container{\r\n    position:absolute;\r\n    left:0;\r\n    top:0;\r\n    width:100%;\r\n    height: 56px;\r\n    background-color: var(--button-color);\r\n    display: flex;        \r\n    align-items: center;\r\n}\r\n\r\n.nav{\r\n    width: 100%;\r\n    padding-inline:40px;\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n}\r\n\r\n.logo{\r\n    height: 30px;\r\n    width:30px;\r\n}\r\n\r\n.logo img{\r\n    width:100%;\r\n    height: 100%;\r\n}\r\n\r\n.buttonContainer{\r\n    display: flex;\r\n    gap:10px;\r\n}\r\n\r\n.button{\r\n    color: var(--text-color);\r\n    border:none;\r\n    outline:none;\r\n    padding:8px 20px;\r\n    border-radius: 8px;\r\n    box-shadow: 1px 1px 1p rgb(0,0,0.5);\r\n    background-color: var(--text-color-white);\r\n    cursor: pointer;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Navbar_container__Jqvfn`,
	"nav": `Navbar_nav__mtp4d`,
	"logo": `Navbar_logo__nkjp8`,
	"buttonContainer": `Navbar_buttonContainer__Ub2ms`,
	"button": `Navbar_button__hTaf2`
};
export default ___CSS_LOADER_EXPORT___;
