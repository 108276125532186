// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoginForm_container__JiIBG{
    display: flex;
    flex-direction: column;
    gap:30px;
    align-items: center;
}

.LoginForm_button__e8ijz{    
    background-color: var(--text-color-white);
    color: var(--text-color);
    padding: 8px 25px;
    outline: none;
    border: none;    
    border-radius: 8px;    
    cursor:pointer;
}

.LoginForm_message__i88w3{
    color:red;
}`, "",{"version":3,"sources":["webpack://./src/components/Form/LoginForm.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,mBAAmB;AACvB;;AAEA;IACI,yCAAyC;IACzC,wBAAwB;IACxB,iBAAiB;IACjB,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,SAAS;AACb","sourcesContent":[".container{\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap:30px;\r\n    align-items: center;\r\n}\r\n\r\n.button{    \r\n    background-color: var(--text-color-white);\r\n    color: var(--text-color);\r\n    padding: 8px 25px;\r\n    outline: none;\r\n    border: none;    \r\n    border-radius: 8px;    \r\n    cursor:pointer;\r\n}\r\n\r\n.message{\r\n    color:red;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `LoginForm_container__JiIBG`,
	"button": `LoginForm_button__e8ijz`,
	"message": `LoginForm_message__i88w3`
};
export default ___CSS_LOADER_EXPORT___;
